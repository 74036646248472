<style lang="scss">
  .map-container-cabinets{
    height: 350px;
  }
  .sizeStatic-cabinets {
    height: 570px;
  }
  .card_statisc-cabinets {
    height: 323px;
  }
  .card_statisc-cabinets-rect {
    height: 250px;
  }
  .resizeImage {
    height: 500px;
    width: 400px;
    border-radius: 5%;
    border: 2px solid #c3c3c3
  }
</style>
<template>
  <div>
    <b-row>
      <b-col md="12" class="text-right mb-2" v-if="userRole !== 'I'">
        <b-button variant="primarySpore" class="btn-icon" @click="showConfig = !showConfig">
          <feather-icon icon="SettingsIcon" /> Comandos a Gabinete
        </b-button>
      </b-col>
      <b-col md="12" v-if="showConfig">
        <b-card>
          <b-row>
            <b-col md="3" class="mt-2">
              <h4>Modo: <label class="text-warning">{{ response.commandMaintenance === 'P' ? 'En espera' : '' }}</label></h4>
              <b-button
                :variant="response.commandMaintenance === 'P' || maintenance === 'M' ? 'secondary' : 'warning'"
                pill
                :disabled="response.commandMaintenance === 'P' || maintenance === 'M'"
                @click="sendMaintenance(response.uuidCabinet)"
              >
                <feather-icon :icon="response.commandMaintenance === 'P' || maintenance === 'M' ? 'CloudOffIcon' : 'CloudIcon'" />
                {{ response.commandMaintenance === 'P' || maintenance === 'M' ? 'En Mantenimiento' : 'Mantenimiento' }}
              </b-button>
            </b-col>
            <b-col md="3" class="mt-2" v-if="relayData.length > 0">
              <h4>Corte / Reconección: <label class="text-warning">{{ response.commandRelay === 'P' ? 'En espera' : '' }}</label></h4>
              <b-form-checkbox
                class="custom-control-warning"
                name="check-button"
                :disabled="response.commandRelay === 'P'"
                v-model="relay"
                @change="sendCutConect(response.uuidCabinet)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="ZapIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="ZapOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col md="3" class="mt-2">
              <h4>Lectura: <label class="text-warning">{{ response.commandRead === 'P' ? 'En espera' : '' }}</label></h4>
              <b-button
                variant="info"
                pill
                :disabled="response.commandRead === 'P'"
                @click="sendRead(response.uuidCabinet)"
              >
                <feather-icon icon="MailIcon" />
                Solicitar
              </b-button>
            </b-col>
            <b-col md="3" class="mt-2">
              <h4>Reiniciar: <label class="text-warning">{{ response.commandReset === 'P' ? 'En espera' : '' }}</label></h4>
              <b-button
                variant="info"
                :disabled="response.commandReset === 'P'"
                pill
                @click="sendReset(response.uuidCabinet)"
              >
                <feather-icon icon="MailIcon" />
                Solicitar
              </b-button>
            </b-col>
            <b-col md="4" class="mt-2">
              <h4>Tiempo Reporteo: <label class="text-warning">{{ response.commandTime === 'P' ? 'En espera' : '' }}</label></h4>
              <v-select
                v-model="timeReport"
                :options="opcTime"
                :clearable="false"
              />
            </b-col>
            <b-col md="4" class="mt-2">
              <h4 style="color:#ffffff;">. </h4>
              <b-button
                variant="primarySpore"
                :disabled="response.commandTime === 'P'"
                pill
                @click="sendTimeReporte(response.uuidCabinet)"
              >
                <feather-icon icon="SendIcon" />
                Enviar tiempo
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" lg="6" class="mb-2">
        <b-card>
          <b-row>
            <b-col md="6" class="mb-1">
              <h4>Número gabinete: </h4>
              <label class="font-medium-1">{{response.gabinetsNumber}}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Serie: </h4>
              <label class="font-medium-1">{{response.serie}}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Tipo: </h4>
              <label class="font-medium-1">{{(response.type == 'P' ? 'Protecsa': response.type == 'N' ? 'Nansen': response.type == 'S' ? 'Siemens': 'Otro') }}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Agencia: </h4>
              <label class="font-medium-1">{{response.agencia}}</label><br>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Fecha Instalación: </h4>
                <label class="font-medium-1">{{ response.installedAt }}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Fecha Ultima transmisión: </h4>
              <label class="font-medium-1">{{ response.transmitedAt }}</label>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Señal: </h4>
                <icon-signal-percent class="align-middle" width="25" height="25" iconColor="#000" :percent="parseInt(response.sign)" />
                <span class="align-middle">
                  {{ getSignalPercent(response.sign) }}
                </span>
            </b-col>
            <b-col md="6" class="mb-1">
              <h4>Registros SiNaMed: </h4>
              <label class="cursor-pointer font-medium-1" style="font-size: 20px" @click="redirectSinamed(uuid)">Ver detalle
                &nbsp;
                <feather-icon icon="ExternalLinkIcon" size="18"/>
              </label>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="6" md="12" v-if="response.transmitedAt === '-'">
        <b-card class="card_statisc-cabinets">
          <b-row>
            <b-col md="12" class="mb-2 mt-5">
              <statistic-card-vertical
                icon="WifiOffIcon"
                statistic="No ha transmitido aún"
                statistic-title="Estado"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="3" md="12" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-cabinets">
          <b-row>
            <b-col md="12" class="mb-2 mt-5">
              <statistic-card-vertical
                :icon="response.status === 'N' ? 'CheckIcon' : 'AlertTriangleIcon'"
                :statistic="response.status === 'N' ? 'Seguro' : 'Mantenimiento'"
                statistic-title="Modo"
                :color="response.status === 'N' ? 'success' : 'warning'"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" lg="3" v-if="response.transmitedAt != '-'">
        <b-card class="card_statisc-cabinets">
          <b-row>
            <b-col md="12" class="mb-2 mt-5">
              <statistic-card-vertical
                :icon="(response.repeat === 2 ? 'AlertTriangleIcon' : (response.repeat === 1 ? 'AlertTriangleIcon' : 'CheckIcon'))"
                :statistic="(response.repeat === 2 ? 'Medidores repetidos en otros Gabinetes' : (response.repeat === 1 ? 'Medidores repetidos en el Gabinete' : 'Número de medidores Correcto'))"
                statistic-title="Duplicados"
                :color="(response.repeat === 2 ? 'warning' : (response.repeat === 1 ? 'warning' : 'success'))"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6" md="12" v-if="response.transmitedAt != '-'">
        <a href="#alertas">
          <b-card class="card_statisc-cabinets-rect">
            <b-row>
              <b-col
                md="12"
                class="mb-1 mt-1"
              >
                <statistic-card-vertical
                  icon="BellOffIcon"
                  :statistic="countAlert > 0 ? 'Con Alertas' : 'Sin Alertas'"
                  statistic-title="Estado ( ver más )"
                  :color="countAlert > 0 ? 'danger' : 'success'"
                />
              </b-col>
            </b-row>
          </b-card>
        </a>
      </b-col>
      <b-col lg="6" md="12" v-if="response.transmitedAt != '-' && response.transmitting === 0">
        <b-card class="card_statisc-cabinets-rect">
          <b-row>
            <b-col
              md="12"
              class="mt-1 mb-2"
            >
              <statistic-card-vertical
                icon="WifiOffIcon"
                statistic="No ha transmitido las últimas 24 hrs"
                statistic-title="Estado"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6" md="12" v-if="response.transmitedAt != '-' && response.transmitting === 1">
        <b-card class="card_statisc-cabinets-rect">
          <b-row>
            <b-col
              md="12"
              class="mb-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                statistic="Transmitiendo"
                statistic-title="Estado"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isLatlong">
      <b-col md="12">
        <b-card>
            <b-card-body>
              <b-row>
                <b-col md="10">
                    <h4>Ubicación</h4>
                </b-col>
                <b-col md="12">
                  <div class="map-container-cabinets basic-example" v-if="status_mapa">
                    <l-map :center="center" :zoom="zoom" v-if="response" :options="{attributionControl: false}">
                      <l-tile-layer :url="url">
                      </l-tile-layer>
                      <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
                      <l-marker :lat-lng="marker" ref="position_marker">
                        <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor">
                          <b-img
                            rounded
                            :src="require(`@/assets/images/markers/0.svg`)"
                          />
                        </l-icon>
                      </l-marker>
                    </l-map>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Medidores</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="12" class="text-center" v-if="responseMetering.length === 0">
                <h5>No hay medidores en el Gabinete</h5>
              </b-col>
            </b-row>
            <b-table
              v-if="responseMetering.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              :items="responseMetering"
              :fields="tableColumnsMetering"
            >
              <template #cell(Number)="data">
                <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.number">
                  <b-avatar
                    v-if="data.item.number"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.number.replace(/^(0)+/, '') }}</span>
                </div>
              </template>

              <template #cell(storedEnergy)="data">
                <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.storedEnergy">
                  <b-avatar
                    v-if="data.item.storedEnergy"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/battery.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.storedEnergy, 2) }} Kw/h</span>
                </div>
              </template>
              <template #cell(Position)="data">
                <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.position">
                  <b-avatar
                    v-if="data.item.position"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/alias.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.position }}</span>
                </div>
              </template>
              <template #cell(Status)="data">
                <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
                  <b-avatar
                    v-if="data.item.status"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    :variant="parseInt(data.item.status) === 1 ? 'success' : 'secondary'"
                  >
                    <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 1" icon="ZapIcon" size="18" />
                    <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 0" icon="ZapOffIcon" size="18"/>
                  </b-avatar>
                  <b>
                    <span class="align-text-top text-capitalize ml-1" :class="parseInt(data.item.status) === 1 ? 'text-success' : ''">{{parseInt(data.item.status) === 1 ? 'con servicio' : 'sin servicio' }}</span>
                  </b>
                </div>
              </template>
              <template #cell(Transmited)="data">
                <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.transmitedAt">
                  <b-avatar
                    v-if="data.item.transmitedAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ format(data.item.transmitedAt) }}</span>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="responseImage.image1 || responseImage.image2 || responseImage.image3">
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Evidencias de la instalación</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="4" v-if="responseImage.image1">
                  <b-img class="resizeImage" :src="`data:image/png;base64,${responseImage.image1}`"/>
              </b-col>
              <b-col md="4" v-if="responseImage.image2">
                  <b-img class="resizeImage" :src="`data:image/png;base64,${responseImage.image2}`"/>
              </b-col>
              <b-col md="4" v-if="responseImage.image3">
                  <b-img class="resizeImage" :src="`data:image/png;base64,${responseImage.image3}`"/>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div id="alertas"></div>
    <b-row v-if="response.transmitedAt != '-'">
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Alertas</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <statusItem v-for="item in alerts" :key="item.name"
                :status="item.value === '0' ?'success':'danger'"
                :description="item.label"
              />
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card>
          <b-card-header>
            <b-card-title>Últimos comandos</b-card-title>
          </b-card-header>
          <b-card-body class="sizeStatic-cabinets" style="overflow-y:scroll;">
            <b-row>
              <b-col md="12" class="text-center" v-if="responseCommands.length === 0">
                <h5>No hay comandos en el Gabinete</h5>
              </b-col>
            </b-row>
            <app-timeline>
              <app-timeline-item variant="primarySpore" v-for="item in responseCommands" :key="item.id">
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <h6>{{ item.type === 'CT' ? 'Tiempo Reporteo' : (item.type === 'CS' ? 'Lectura / Mantenimiento / Reinicio' : (item.type === 'M' ? 'Estatus Relay' : '')) }}</h6>
                  <small class="timeline-item-time text-nowrap text-muted ml-1">{{ format(item.createdAt) }}</small>
                </div>
                <p>{{item.status === 'T' ? 'Transmitido' : 'Pendiente'}}</p>
              </app-timeline-item>
            </app-timeline>
          </b-card-body>
          <b-row>
            <b-col md="12" class="text-center">
              <b-link :to="`/gabinets/${uuid}/commands`">Ver más</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-card-header>
            <b-card-title>Últimas lecturas</b-card-title>
          </b-card-header>
          <b-card-body class="sizeStatic-cabinets" style="overflow-y:scroll;">
            <b-row>
              <b-col md="12" class="text-center" v-if="responseRead.length === 0">
                <h5>No hay lecturas en el Gabinete</h5>
              </b-col>
            </b-row>
            <b-table
              v-if="responseRead.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              :items="responseRead"
              :fields="tableColumnsRead"
            >
              <template #cell(Transmited)="data">
                <div class="text-nowrap selectable" v-if="data.item.createdAt">
                  <b-avatar

                    v-if="data.item.createdAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ format(data.item.createdAt) }}</span>
                </div>
              </template>
            </b-table>
          </b-card-body>
          <b-row>
            <b-col md="12" class="text-center">
              <b-link :to="`/gabinets/${uuid}/logs`">Ver más</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BImg, BCardTitle, BCardHeader, BTable, BAvatar, BButton, BFormCheckbox, BLink
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { formatDateLanguages, empty, decimales } from '@/libs/tools/helpers'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlAttribution
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import statusItem from '@core/spore-components/statusItem/statusItem.vue'
import iconSignalPercent from '@core/spore-components/icons/iconSignalPercent.vue'

/* global L:true */
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlAttribution,
    LIcon,
    BRow,
    BCol,
    BCard,
    BCardBody,
    StatisticCardVertical,
    BImg,
    BCardTitle,
    BCardHeader,
    AppTimeline,
    AppTimelineItem,
    BTable,
    BAvatar,
    statusItem,
    iconSignalPercent,
    BButton,
    BFormCheckbox,
    BLink
  },
  data () {
    return {
      isLatlong: false,
      iconSize: 45,
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      uuid: this.$route.params.uuid,
      response: [],
      responseMetering: [],
      responseRead: [],
      responseCommands: [],
      responseImage: [],
      zoom: 18,
      center: L.latLng(47.41461, -1.206607),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: false,
      marker: L.latLng(47.41461, -1.206607),
      staticAnchor: [12, 27],
      status_mapa: false,
      timeReport: [],
      showConfig: false,
      maintenance: false,
      relay: false,
      relayData: '',
      countAlert: 0,
      tableColumnsMetering: [
        { key: 'Number', label: 'Número', sortable: true },
        { key: 'storedEnergy', label: 'Acumulado', sortable: true },
        { key: 'Position', label: 'Posición', sortable: true },
        { key: 'Status', label: 'Estatus', sortable: true },
        { key: 'Transmited', label: 'Última transmisión', sortable: true }
      ],
      tableColumnsRead: [
        { key: 'Transmited', label: 'Última transmisión', sortable: true }
      ],
      opcTime: [
        {
          label: '10 segundos',
          title: '10 segundos',
          value: '1'
        },
        {
          label: '30 segundos',
          title: '30 segundos',
          value: '3'
        },
        {
          label: '1 minuto',
          title: '1 minuto',
          value: '6'
        },
        {
          label: '10 minuto',
          title: '10 minuto',
          value: '60'
        },
        {
          label: '30 minuto',
          title: '30 minuto',
          value: '180'
        },
        {
          label: '1 hora',
          title: '1 hora',
          value: '360'
        },
        {
          label: '2 horas',
          title: '2 horas',
          value: '720'
        },
        {
          label: '3 horas',
          title: '3 horas',
          value: '1080'
        },
        {
          label: '4 horas',
          title: '4 horas',
          value: '1440'
        },
        {
          label: '5 horas',
          title: '5 horas',
          value: '1800'
        },
        {
          label: '6 horas',
          title: '6 horas',
          value: '2160'
        },
        {
          label: '7 horas',
          title: '7 horas',
          value: '2520'
        },
        {
          label: '8 horas',
          title: '8 horas',
          value: '2880'
        },
        {
          label: '9 horas',
          title: '9 horas',
          value: '3240'
        },
        {
          label: '10 horas',
          title: '10 horas',
          value: '3600'
        },
        {
          label: '11 horas',
          title: '11 horas',
          value: '3960'
        },
        {
          label: '12 horas',
          title: '12 horas',
          value: '4320'
        },
        {
          label: '13 horas',
          title: '13 horas',
          value: '4680'
        },
        {
          label: '14 horas',
          title: '14 horas',
          value: '5040'
        },
        {
          label: '15 horas',
          title: '15 horas',
          value: '5400'
        },
        {
          label: '16 horas',
          title: '16 horas',
          value: '5760'
        },
        {
          label: '17 horas',
          title: '17 horas',
          value: '6120'
        },
        {
          label: '18 horas',
          title: '18 horas',
          value: '6480'
        },
        {
          label: '19 horas',
          title: '19 horas',
          value: '6840'
        },
        {
          label: '20 horas',
          title: '20 horas',
          value: '7200'
        },
        {
          label: '21 horas',
          title: '21 horas',
          value: '7560'
        },
        {
          label: '22 horas',
          title: '22 horas',
          value: '7920'
        },
        {
          label: '23 horas',
          title: '23 horas',
          value: '8280'
        },
        {
          label: '24 horas',
          title: '24 horas',
          value: '8640'
        }
      ],
      alerts: [
        {
          name: 'absencePhaseA',
          label: 'Ausencia fase A',
          value: 0
        },
        {
          name: 'absencePhaseB',
          label: 'Ausencia fase B',
          value: 0
        },
        {
          name: 'absencePhaseC',
          label: 'Ausencia fase C',
          value: 0
        },
        {
          name: 'openPassBox',
          label: 'Caja de paso abierta',
          value: 0
        },
        {
          name: 'maintenance',
          label: 'Mantenimiento',
          value: 0
        },
        {
          name: 'loadProfileError',
          label: 'Error en perfil de carga',
          value: 0
        },
        {
          name: 'communicationError',
          label: 'Error de comunicación',
          value: 0
        },
        {
          name: 'dateTimeInvalid',
          label: 'Fecha y hora no válida',
          value: 0
        },
        {
          name: 'fraudMeter1',
          label: 'Fraude medidor 1',
          value: 0
        },
        {
          name: 'fraudMeter2',
          label: 'Fraude medidor 2',
          value: 0
        },
        {
          name: 'fraudMeter3',
          label: 'Fraude medidor 3',
          value: 0
        },
        {
          name: 'fraudMeter4',
          label: 'Fraude medidor 4',
          value: 0
        },
        {
          name: 'fraudMeter5',
          label: 'Fraude medidor 5',
          value: 0
        },
        {
          name: 'fraudMeter6',
          label: 'Fraude medidor 6',
          value: 0
        },
        {
          name: 'fraudMeter7',
          label: 'Fraude medidor 7',
          value: 0
        },
        {
          name: 'fraudMeter8',
          label: 'Fraude medidor 8',
          value: 0
        },
        {
          name: 'fraudMeter9',
          label: 'Fraude medidor 9',
          value: 0
        },
        {
          name: 'fraudMeter10',
          label: 'Fraude medidor 10',
          value: 0
        },
        {
          name: 'fraudMeter11',
          label: 'Fraude medidor 11',
          value: 0
        },
        {
          name: 'fraudMeter12',
          label: 'Fraude medidor 12',
          value: 0
        },
        {
          name: 'simDoorOpen',
          label: 'Puerta SIM abierta',
          value: 0
        },
        {
          name: 'hotSnapOcurred',
          label: 'Ocurrio Hot Swap',
          value: 0
        },
        {
          name: 'temperatureSensorActivated',
          label: 'Sensor temperatura',
          value: 0
        },
        {
          name: 'systemJustPoweredUp',
          label: 'Sistema energizado',
          value: 0
        },
        {
          name: 'notPulse24Hrs',
          label: 'Sin Pulso 24 Hr',
          value: 0
        },
        {
          name: 'notConsumption',
          label: 'Sin consumo',
          value: 0
        }
      ]
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 4, this.iconSize * 1.15]
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    detail (uuid) {
      this.$router.push(`/meters/${uuid}/detail`)
    },
    redirectSinamed (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/gabinets/${uuid}/${this.response.uuidCabinet}/sinamedLogs`)
      }
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    getSignalPercent (value) {
      value = parseInt(value)
      if (value === 99) return 'No conocido o no detectado'
      else if (value > 30) return '-52 dBm o muy buena'
      else if (value > 1 && value <= 30) return '-110... - 54 dBm'
      else if (value === 1) return '-111 dBm'
      else if (value === 0) return '-115 dBm o menor'
      return value
    },
    async getDetail () {
      const params = {
        url: `/devices/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = {
          gabinetsNumber: data.data.data.cfeCabinet.number,
          type: data.data.data.cfeCabinet.type,
          agencia: !empty(data.data.data.cfeAgency) ? data.data.data.cfeAgency.name : '',
          serie: data.data.data.serie,
          installedAt: (data.data.data.installedAt ? this.format(data.data.data.installedAt) : '-'),
          transmitedAt: (data.data.data.transmitedAt ? this.format(data.data.data.transmitedAt) : '-'),
          status: data.data.data.status,
          repeat: parseInt(data.data.data.cfeCabinet.status),
          sign: (data.data.data.sign ? data.data.data.sign : 99),
          uuidCabinet: data.data.data.cfeCabinet.uuid,
          transmitting: data.data.data.transmitting,
          commandMaintenance: data.data.data.commandMaintenance,
          commandRead: data.data.data.commandRead,
          commandRelay: data.data.data.commandRelay,
          commandReset: data.data.data.commandReset,
          commandTime: data.data.data.commandTime
        }
        this.responseMetering = data.data.data.cfeCabinet.cfeMeters
        this.responseCommands = data.data.data.cfeCommands
        this.responseRead = data.data.data.cfeCabinetsRecords
        this.relayData = !empty(this.responseMetering) ? data.data.data.cfeCabinet.cfeMeters : []
        this.maintenance = this.response.status
        this.relay = !empty(this.responseMetering) ? parseInt(data.data.data.cfeCabinet.cfeMeters[0].status) === 1 : false
        this.timeReport = this.opcTime.filter(el => { return el.value === data.data.data.time })
        if (data.data.data.latlong) {
          const lat = data.data.data.latlong.split(',')[0]
          const long = data.data.data.latlong.split(',')[1]
          this.isLatlong = true
          this.marker = L.latLng(lat, long)
          this.center = L.latLng(lat, long)
        }

        for (let index = 0; index < this.alerts.length; index++) {
          if (data.data.data.cfeCabinet[this.alerts[index].name] === '1') {
            this.countAlert++
          }
          this.alerts[index].value = data.data.data.cfeCabinet[this.alerts[index].name]
        }
        this.alerts.filter(el => { return el.name === 'maintenance' })[0].value = (this.response.status === 'M' ? '1' : '0')
        this.status_mapa = true
      })
    },
    sendCutConect (uuid) {
      this.sendComand(uuid, 'C', 'R', this.relay ? '1' : '0')
    },
    sendRead (uuid) {
      this.sendComand(uuid, 'C', 'L', '1')
    },
    sendReset (uuid) {
      this.sendComand(uuid, 'C', 'S', '1')
    },
    sendMaintenance (uuid) {
      this.sendComand(uuid, 'C', 'M', '1')
    },
    sendTimeReporte (uuid) {
      this.sendComand(uuid, 'C', 'T', this.timeReport.value)
    },
    sendComand (uuid, type, command, state) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Estos comandos se verán reflejados una vez que el dispositivo transmita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/devices/command/${uuid}`,
            method: 'POST',
            params: {
              type: type,
              command: command,
              state: state
            }
          }
          await request(params).then(data => {
            this.showConfig = false
          })
        }
        this.getDetail()
      })
    },
    async getImage () {
      const params = {
        url: `/devices/images/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.responseImage = data.data.data
      })
    }
  },
  created () {
    this.getDetail()
    this.getImage()
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
